import { Helmet as HelmetRaw } from 'react-helmet'

import React, { FC } from 'react'

interface Props {
	name?: string
	children: React.ReactNode
}

const Helmet: FC<Props> = props => {
	const { name, children, ...rest } = props

	return (
		<HelmetRaw {...rest} defer={false}>
			{name ? <title>{name} | Ruh Tracker</title> : <title>Ruh Tracker</title>}
			{children}
		</HelmetRaw>
	)
}

export default Helmet
