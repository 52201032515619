import { gql, useQuery } from '@apollo/client'

import React, { ReactElement, FC, StrictMode, ReactNode } from 'react'

import Helmet from '~/components/Helmet'

import { GlobalErrorsLog } from './__generated__/GlobalErrorsLog'
import { normalizeErrorsLog } from './helpers'
import './normilio.css'
import Private from './private'

// the page wrapper. Each page should be wrapped into it.

const GetGlobalErrorsLog = gql`
	query GlobalErrorsLog {
		globalErrorsLog @client {
			operationName
			errors
			delay
			attempt
		}
	}
`

export interface Props {
	public?: boolean
	name?: string
	helmetChildren?: ReactNode
	children?: React.ReactNode
}

const Global: FC<Omit<Props, 'public'>> = props => {
	const { children, helmetChildren, ...rest } = props
	return (
		<StrictMode>
			<Helmet {...rest}>{helmetChildren}</Helmet>
			{children}
		</StrictMode>
	)
}

const Page: FC<Props> = props => {
	const { public: isPublicPage, children, ...rest } = props

	// fetch error data from cache
	const { data } = useQuery<GlobalErrorsLog>(GetGlobalErrorsLog, { fetchPolicy: 'cache-only' })
	const normalizedLog = data ? normalizeErrorsLog(data) : []

	const content = isPublicPage ? children : <Private>{children}</Private>

	// otherwise display errors alongise the app so user can see that we are refetching
	return (
		<Global {...rest}>
			{normalizedLog.length > 0 && JSON.stringify(normalizedLog)}
			{content}
		</Global>
	)
}

// a HOC that allow you to write withPage(YourComponent) instead of <Page><YourComponent/></Page>
export const withPage = function <T>(Element: FC<T>, pageProps?: Props) {
	return function PageHOC(props?: T): ReactElement {
		return (
			<Page {...pageProps}>
				<Element {...(props as any)} />
			</Page>
		)
	}
}

export default Page
