import { gql, useQuery } from '@apollo/client'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'

import React, { FC } from 'react'

import { QueryUserData, QueryUserData_me } from './__generated__/QueryUserData'
import { AuthContext } from './context'

// if user are not signed in, take them to login. Otherwise, allow them to let through and
// provide a global context with their login data

const queryMe = gql`
	query QueryUserData {
		me {
			id
			username
			email
			role {
				name
			}
		}
	}
`

interface PrivateProps {
	children: React.ReactNode
}

const Private: FC<PrivateProps> = props => {
	const { data: userData, loading } = useQuery<QueryUserData>(queryMe, {
		context: {
			retry: false,
		},
	})

	if (loading)
		return (
			<Flex justify='center' gap='1em' p='1em'>
				<Spinner />
				<Text color='gray.500' fontSize='sm'>
					Loading
				</Text>
			</Flex>
		)

	if (!userData || !userData.me) {
		navigate(`/login`)
		return null
	}

	const value: Omit<QueryUserData_me, '__typename'> = userData.me

	return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
}

export default Private
