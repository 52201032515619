import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'
import { styled } from 'linaria/react'

import { FC } from 'react'

const Button: FC<ButtonProps> = props => {
	const { children, leftIcon, rightIcon, ...restProps } = props

	return (
		<StyledButton {...restProps}>
			{leftIcon && <IconWrapper>{leftIcon}</IconWrapper>}
			{children}
			{rightIcon && <IconWrapper>{rightIcon}</IconWrapper>}
		</StyledButton>
	)
}

const StyledButton = styled(ChakraButton)`
	display: flex;
	gap: 0.5em;
	align-items: center;
	justify-content: center;
`

const IconWrapper = styled.span`
	line-height: 0;
`

export default Button
