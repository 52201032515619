import { ErrorInfo } from '~/gatsby-theme-apollo/client'

import { GlobalErrorsLog } from './__generated__/GlobalErrorsLog'

export interface NormalizedLogObject {
	[key: string]: ErrorInfo
}

// get one error per operation name, the most recent error of all of them
export const normalizeErrorsLog = (log: GlobalErrorsLog): ErrorInfo[] => {
	const result: NormalizedLogObject = {}

	// sort errors so the older ones are on top
	const errorsLog = [...log.globalErrorsLog].sort((a, b) => {
		if (!a || !b) return 0
		return a.attempt - b.attempt
	})

	// put errors into object, essentially making them unique.
	// object is like this:
	// { operationName: lastErrorData }
	errorsLog.forEach(logRecord => {
		if (logRecord?.operationName) result[logRecord?.operationName] = logRecord
	})
	return Object.values(result)
}
