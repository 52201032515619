import { ToastId, useToast as chakraToast, UseToastOptions } from '@chakra-ui/react'

export const toastConfig: UseToastOptions = {
	duration: 1500,
	isClosable: true,
	position: 'top',
}

const useToast = (
	config?: UseToastOptions
): ((options?: UseToastOptions | undefined) => ToastId | undefined) =>
	chakraToast({ ...toastConfig, ...config })

export default useToast
