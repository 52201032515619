import { ApolloError } from '@apollo/client'
import { ToastId, UseToastOptions } from '@chakra-ui/react'

import { useState, useEffect } from 'react'

export const useIsBrowser = (): boolean => {
	const [browser, setBrowser] = useState<boolean>(false)
	useEffect(() => setBrowser(true), [])
	return browser
}

export const scrollToTop = (): void => {
	window.scrollTo(0, 0)
}

export const usePreventLeavePage = (shouldPrevent: boolean): void =>
	useEffect(() => {
		window.addEventListener('beforeunload', (e: BeforeUnloadEvent) => {
			if (e && shouldPrevent) e.returnValue = 'prevent'
			if (!shouldPrevent) return
			return 'prevent'
		})
	}, [shouldPrevent])

// https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals
// example:
// pluralize(['word', 'words'], 2)
// > 'words'
export const pluralize = (words: [string, string], number: number): string => {
	if (number === 1) return words[0]
	return words[1]
}

export const useAsyncEffect = (functn: () => Promise<void>, deps?: React.DependencyList): void =>
	useEffect(() => {
		functn()
	}, deps)

export const joinValues = (...vals: (string | null | undefined)[]): string => {
	return vals.filter(Boolean).join(' ')
}

export const onError = (
	error: ApolloError,
	toast: (options?: UseToastOptions | undefined) => ToastId | undefined
): ToastId | undefined => toast({ title: error.message, status: 'error', position: 'top-right' })

export const strMaxLength = (str: string | undefined, maxLength: number): string | undefined => {
	if (!str) return str
	if (str.length <= maxLength) return str
	return str.slice(0, maxLength) + '...'
}
