import { createContext } from 'react'

import { QueryUserData_me } from './__generated__/QueryUserData'

// global context that stores the data about authenticated user
export const AuthContext = createContext<Omit<QueryUserData_me, '__typename'>>({
	id: '',
	username: '',
	email: '',
	role: null,
})

AuthContext.displayName = 'AuthContext'
